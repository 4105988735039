import * as React from 'react';
import { Form, Icon, Input, Button } from 'antd';
import {Redirect} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Reducers/Actions/ActionsTS';
import { IApiService } from '../../Services/Shared/ApiService';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import IoCContainer from '../../Wires/Bootstrapper';

import { RouteComponentProps, Route } from "react-router";
import { withRouter } from "react-router-dom";
import { INotifyService } from '../../Services/Shared/NotifyService';
import * as Api from "../../Constants/ApiUrlConstants";
import { ILoginModel, LoginModel } from '../../Models/LoginModel';
import { IAuthService } from '../../Services/Security/AuthServiceTs';
import QueueAnim from 'rc-queue-anim';
import { FormComponentProps } from 'antd/lib/form';

import logo from "./../../assets/images/Tpocket_Marchio.png"
import WrappedChangePasswordFormConnect from './ChangePassword';


export class Login extends React.Component<RouteComponentProps & FormComponentProps, ILoginModel> 
{
    private apiClient: IApiService
    private notifyService: INotifyService
    private authService :IAuthService

    constructor(props) {
        super(props);
        this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this.notifyService= IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);

        this.state = new LoginModel();
    }

    onChangeText = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let data = this.state;
        data[name] = value;
        this.setState(data);

        this.props.form.setFieldsValue({
            Username: this.state.UserName,
            Password: this.state.PassWord
        });
    }

    login = () => {
        const param = { username: this.state.UserName, password: this.state.PassWord }
        this.apiClient.post<any>(Api.Login, param,
            (resp) => {
                if (resp.IsValid == true) {
                    let model = new LoginModel();
                    model.UserName = this.state.UserName;
                    model.Token = resp.Token;
                    debugger

                    if (resp.IsTemporanyPassword) {
                        this.props.history.push('/ChangePassword/' + resp.Token);
                        return;
                    }
                    this.authService.signInAndReload(model);
                }
            },
            (err) => {
                this.notifyService.error("Errore", err);
            }
        );

        // axios.get(Api.Login, {params: {username: this.state.user, password: this.state.password}})
        //   .then((resp) => {
        //     
        //     if (resp.data.IsValid == true) {
        //       authService.signIn({user: this.state.user, token: resp.data.Token},
        //         () => {
        //           this.props.setLogged(true);
        //           this.props.changeUser(this.state.user);
        //         });
        //     } else {
        //       alert('Errore');
        //     }
        //   });
    }
 
    doLogin = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) this.login()
        });
    }

    render() 
    {
        const { getFieldDecorator } = this.props.form;
        const FormItem = Form.Item;
        const {target} = this.props.location.state || {target: {pathname: '/'}};       

        if (this.authService.isAuthenticated()) {
            return (<Redirect to={target} />);
        }

        return (
            <div>
                <Form className="login-form">
                    <FormItem>
                        {getFieldDecorator('Username', {
                            rules: [{ required: true, message: 'Inserisci username' }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                name="UserName"
                                placeholder="Username"
                                onChange={this.onChangeText} />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('Password', {
                            rules: [{ required: true, message: 'Inserisci password'}],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                name="PassWord"
                                type="Password"
                                onChange={this.onChangeText}
                                placeholder="Password" />
                        )}
                    </FormItem>
                    <br/>
                    <Button type="primary" htmlType="submit" className="submit-button" onClick={this.doLogin}>Accedi</Button>
                </Form>
            </div>
        );
    };

}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => bindActionCreators(
  actions,
  dispatch,
);

const WrappedNormalLoginFormConnect = withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login)));


class LoginWrap extends React.Component<any,any>  {

  render() {
    return (
      <div className="body-inner">
        <div className="box box-default">
          <div className="box-body">

                    <section className="logo text-center">
                        <img src={logo} width='70%' height='70%' />
            </section>
                    <Route exact path="/" component={WrappedNormalLoginFormConnect} />
                    <Route path="/ChangePassword/:token" component={WrappedChangePasswordFormConnect} />
            
          </div>
        </div>

        {/* <div className="additional-info">
          <a href="#/sign-up">Sign up</a>
          <span className="divider-h" />
          <a href="#/forgot-password">Forgot your password?</a>
        </div>*/}
      </div> 
    );
  }
}

const Page = () => (
  <div className="page-login">
    <div className="main-body">
      <QueueAnim type="bottom" className="ui-animate">
        <div key="1">
          <LoginWrap />
        </div>
      </QueueAnim>
    </div>
  </div>
);

export default Page;