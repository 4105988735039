export class FilesystemVirtualeStateEditModel {
    Id: string
    Code: string
    Cliente_Id: string
    TipoRecord: string
    Parent_Id: string
    Estensione: string
    FileUrl: string
    File_Id: string
    IsDirectory: boolean
    ContentLength: number
    ContentType: string
    ThumbUrl: string
    CreationDate: Date
    Figli: Array<FilesystemVirtualeStateEditModel>
}

export class FilesystemVirtualeUploadModel {
    Cliente_Id: string
    Parent_Id: string
    Files: Array<File>
}