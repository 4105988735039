const SERVICE_IDENTIFIER = {
    TOOLBAR: Symbol.for("Toolbar"),
    TEST: Symbol.for("Test"),
    MENU_SERVICE: Symbol.for("MenuService"),
    NOTIFY: Symbol.for("Notify"),
    API_SERVICE: Symbol.for("ApiService"),
    API_SERVICE_EXT: Symbol.for("ApiServiceExt"),
    APPLAYOUT_SERVICE: Symbol.for("AppLayout"),    
    AUTH_SERVICE: Symbol.for("AuthService"),  
    STORE: Symbol.for("Store"),  
    HEADER: Symbol.for("Head"),  
    HEADER_ACTION: Symbol.for("Head_Action"),  
    API_LOADER_ACTION: Symbol.for("ApiLoader_Action"),  
    STIPULATI_SERVICE: Symbol.for("StipulatiService"),  
    ERROR_HANDLER_ACTION: Symbol.for("ErrorHandler_Action"),  
    USER_SERVICE: Symbol.for("UserService"),  
    USER_SEARCH_SERVICE: Symbol.for("UserSearchService"),
    FORMDATA_CONVERTER: Symbol.for("FormDataConverter"),
    SIGNALR: Symbol.for("SignalR"),
    FCM: Symbol.for("FCM"),
    DEEP_LINK: Symbol.for("DeepLink"),

    ADEMPIMENTI_SERVICE: Symbol.for("AdempimentiService"),
    ADEMPIMENTO_SERVICE: Symbol.for("AdempimentoService"),
    ADEMPIMENTO_COPY_SERVICE: Symbol.for("AdempimentoCopyService"),
    ADEMPIMENTO_CUSTOMER_SERVICE: Symbol.for("AdempimentoCustomerService"),
    ADEMPIMENTO_EDIT_SERVICE: Symbol.for("AdempimentoEditService"),
    ADEMPIMENTO_NOTIFICATION_SERVICE: Symbol.for("AdempimentoNotificationService"),
    GRUPPO_SERVICE: Symbol.for("GruppoService"),
    GRUPPO_EDIT_SERVICE: Symbol.for("GruppoEditService"),
    CLIENTE_SERVICE: Symbol.for("ClienteService"),
    CLIENTE_UTENTE_SERVICE: Symbol.for("ClienteUtenteService"),
    CLIENTE_UTENTE_EDIT_SERVICE: Symbol.for("ClienteUtenteEditService"),
    CLIENTI_SERVICE: Symbol.for("ClientiService"),
    DRAWER_SERVICE: Symbol.for("DrawerService"),
    HOME_SERVICE: Symbol.for("HomeServices"),
    SERVICES: Symbol.for("Services"),
    CIRCOLARI_SERVICE: Symbol.for("CircolariService"),
    CIRCOLARE_EDIT_SERVICE: Symbol.for("CircolareEditService"),
    TENANT_EDIT_SERVICE: Symbol.for("TenantEditService"),
    FSVIRTUALE_SERVICE: Symbol.for("FSVirtualeService"),
    IMPORT_CLIENTI_SERVICE: Symbol.for("ImportClientiService"),
    IMPORT_FATTURATO_SERVICE: Symbol.for("ImportFatturatoService"),
    IMPORT_SCADENZE_SERVICE: Symbol.for("ImportScadenzeService"),
    FIRMA_SERVICE: Symbol.for("FirmaService"),
    UTENTI_SERVICE: Symbol.for("UtentiService"),
    UTENTI_EDIT_SERVICE: Symbol.for("UtentiEditService"),
    DEVICE_SERVICE: Symbol.for("DeviceService"),
    LICENZE_SERVICE: Symbol.for("LicenzeService"),
    LICENZA_CLIENTI_SERVICE: Symbol.for("LicenzaClientiService"),
};

export default SERVICE_IDENTIFIER;
