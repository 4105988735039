import * as React from 'react'
import { List, PageHeader, Button, Collapse, Empty } from 'antd';
import moment from 'moment';

import { FilesystemVirtualeStateEditModel, FilesystemVirtualeUploadModel } from './../../../Models/FilesystemVirtualeModels'
import { IFSVirtualeService } from '../../../Services/FSVirtualeService';
import SERVICE_IDENTIFIER from "../../../Wires/Identifiers";
import IoCContainer from '../../../Wires/Bootstrapper';
import './FS.css'
import { TTAvatar } from '../TTAvatar';
import { TTUploadLayout } from '../TTUploadLayout';
import { TTModal } from '../TTModal';
import { ModalRenameFile, ModalRenameFileState } from './ModalRenameFile';
import { ModalMoveFile } from './ModalMoveFile';
import { TTSingleSelectTable } from '../TTSingleSelectTable';
import { TTMultiUpload } from '../TTMultiUpload';

const { Panel } = Collapse;

export class FileSystemState extends FilesystemVirtualeStateEditModel {
    public Uploading: boolean
    public Files: Array<File>
    public SelectedItem: FilesystemVirtualeStateEditModel
    public RenameModal: boolean
    public MoveModal: boolean
}

export interface IFileSystemProps {
    clienteId: string
    selectionMode?: boolean
    selectedItem?: (item: FilesystemVirtualeStateEditModel) => void
    showUploader?: boolean
    foldersOnly?: boolean
}

export class FileSystemWindow extends React.Component<IFileSystemProps, FileSystemState> {

    _service: IFSVirtualeService

    static defaultProps = {
        selectionMode: false,
        showUploader: true,
        foldersOnly: false
    }

    constructor(props) {
        super(props)

        var state = new FileSystemState()
        state.Files = []

        this.state = state
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE)
    }

    componentDidMount() {
        this._service.GetRootFolder(this.props.clienteId).then(resp => {
            this.loadFolder(resp.Id)
        });
    }

    loadFolder(id: string) {
        this._service.GetById(id, this.props.foldersOnly).then(resp => {
            this.setState(resp);
        });
    }

    selectItem = (item: FilesystemVirtualeStateEditModel) => {
        this.setState({ SelectedItem: item })
        this.props.selectedItem(item)
    }

    openFolder = (item: FilesystemVirtualeStateEditModel) => {
        // Notificare l'assenza di un elemento selezionato
        this.selectItem(null)

        this.loadFolder(item.Id)
    }

    openFile = (item: FilesystemVirtualeStateEditModel) => {
        this._service.GetFile(item)
    }

    openItem = (item: FilesystemVirtualeStateEditModel) => {
        if (item.IsDirectory) {
            this.openFolder(item)
        }
    }

    onBack = () => {
        // Popolazione lista elementi
        this._service.GetById(this.state.Parent_Id, this.props.foldersOnly).then(resp => {
            this.setState(resp);
        });
    }

    /*** Layout ***/
    getAvatar = (item: FilesystemVirtualeStateEditModel) => {
        if (item.IsDirectory) {
            return <TTAvatar tticon="cartella" />
        } else {
            return <TTAvatar tticon="documento" />
        }
    }

    formatDate = (date: Date) => {
        return date == null ? "" : moment(date).format("LLL")
    }

    getRowLayout = (text: string, item: FilesystemVirtualeStateEditModel) => {
        return <List.Item.Meta
            avatar={this.getAvatar(item)}
            description={item.IsDirectory ? "" : this.formatDate(item.CreationDate)}
            title={text} />
    }

    /*** Upload ***/
    onUpdateUploadList = (fileList: Array<File>) => {
        this.setState({ Files: fileList })
    }

    startUpload = () => {
        this.setState({
            Uploading: true,
        })

        const { Id, Cliente_Id, Files } = this.state
        var model: FilesystemVirtualeUploadModel = {
            Parent_Id: Id,
            Files,
            Cliente_Id
        }

        this._service.Upload(model).then(() => {
            this.setState({
                Uploading: false,
                Files: []
            });
            this.loadFolder(Id)
        });
    }

    /**** Toolbar ****/
    onFolderOpen = () => {
        this.openItem(this.state.SelectedItem)
    }

    onFileOpen = () => {
        this.openFile(this.state.SelectedItem)
    }

    onFileRename = () => {
        this.openRename()
    }

    onFileDelete = () => {
        TTModal.confirm({
            title: "Eliminare il file?",
            content: "Non sarà possibile annullare l'operazione",
            onOk: () => {
                this._service.Delete(this.state.SelectedItem.Id).then(resp => {
                    if (resp.IsValid) {
                        // Annullo la selezione del documento in quanto non più esistente
                        this.selectItem(null)
                        this.loadFolder(this.state.Id)
                    }
                });
            }
        })
    }

    onFileMove = () => {
        this.openMove();
    }

    getPageHeaderButtons = () => {
        var arr = []
        if (this.state.SelectedItem.IsDirectory) {
            arr.push(<Button type="primary" key="buttonOpen" onClick={this.onFolderOpen}>Apri</Button>)
        } else {
            if (this.props.selectionMode == false) {
                arr.push(<Button type="primary" key="buttonDel" onClick={this.onFileDelete}>Elimina</Button>)
                arr.push(<Button type="primary" key="buttonRen" onClick={this.onFileRename}>Rinomina</Button>)
                arr.push(<Button type="primary" key="buttonMove" onClick={this.onFileMove}>Sposta</Button>)
                arr.push(<Button type="primary" key="buttonDown" onClick={this.onFileOpen}>Scarica</Button>)
            }
        }

        return arr
    }

    /*** Rename Modal ***/
    closeRename = () => {
        this.setState({ RenameModal: false })
    }

    openRename = () => {
        this.setState({ RenameModal: true })
    }

    RenameCallback = (data: ModalRenameFileState) => {
        const itm = { ...this.state.SelectedItem };
        itm.Code = data.Name

        this._service.Rename(itm).then(resp => {
            if (resp.IsValid) {
                this.loadFolder(this.state.Id)
            }
        })
    }

    /*** Move Modal ***/
    closeMove = () => {
        this.setState({ MoveModal: false })
    }

    openMove = () => {
        this.setState({ MoveModal: true })
    }

    MoveCallback = (parentFolderId: string) => {
        const itm = { ...this.state.SelectedItem };
        itm.Parent_Id = parentFolderId

        this._service.Move(itm).then(resp => {
            if (resp.IsValid) {
                this.loadFolder(this.state.Id)
            }
        })
    }

    render() {
        const { Parent_Id, SelectedItem, Uploading, Files } = this.state

        const headerProps = {
            title: this.state.Code,
            onBack: Parent_Id != null ? () => this.onBack() : undefined,
            extra: SelectedItem != null ? this.getPageHeaderButtons() : undefined
        }

        const tableColumns = [
            {
                title: 'Name',
                dataIndex: 'Code',
                render: this.getRowLayout
            }
        ]

        let fileName = this.state.SelectedItem == null ? "" : this.state.SelectedItem.Code

        let collapse = <span />
        if (this.props.showUploader == true) {
            collapse =
                <div>
                    <br />
                    <Collapse>
                        <Panel header="Caricamento File" key="1">
                            <TTMultiUpload type="drag"
                                updateList={this.onUpdateUploadList}
                                files={Files} >
                                    <TTUploadLayout />
                            </TTMultiUpload>
                            <br />
                            <Button type="primary" onClick={() => this.startUpload()} disabled={Files.length == 0 || Uploading}>
                                Inizia caricamento
                            </Button>
                        </Panel>
                    </Collapse>
                </div>
        }

        return (
            <div>
                <PageHeader {...headerProps}>
                    {collapse}
                </PageHeader>
                <TTSingleSelectTable className="fs-list-bg"
                    columns={tableColumns}
                    dataSource={this.state.Figli}
                    onClick={this.selectItem}
                    onDoubleClick={this.openItem}
                    rowKey="Id"
                    locale={{
                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Nessun documento o cartella" />
                    }}
                    pagination={false}
                    showHeader={false} />

                <ModalMoveFile
                    clienteId={this.props.clienteId}
                    visible={this.state.MoveModal}
                    closeModal={this.closeMove}
                    do={this.MoveCallback} />

                <ModalRenameFile
                    visible={this.state.RenameModal}
                    closeModal={this.closeRename}
                    currentName={fileName}
                    do={this.RenameCallback} />
            </div>
        );
    }
}
