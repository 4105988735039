import * as React from 'react';
import * as signalR from '@aspnet/signalr'
import { inject, injectable, named } from "inversify";
import container from "../../Wires/Bootstrapper";
import { IAuthService } from '../Security/AuthServiceTs';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';

export interface ISignalRServiceHandler {
    AddEventHandlerFor(event: string, handler: (...args: any[]) => void): void;
    RemoveEventHandlerFor(event: string, handler: (...args: any[]) => void): void;
}

export interface IHandlerDelegate {
    newMethod: (...args: any[]) => void
}

@injectable()
export class SignalRServiceHandler implements ISignalRServiceHandler {
    authService: IAuthService;
    connection: signalR.HubConnection;

    delegates: { [id: string]: IHandlerDelegate; } = {};

    AddEventHandlerFor = (event: string, handler: (...args: any[]) => void) => {
        this.connection.on(event, handler);
    };

    RemoveEventHandlerFor = (event: string, handler: (...args: any[]) => void) => {
        this.connection.off(event, handler)
    };

    constructor() {

        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_BASEURL.replace("/api", "") + "/NotificationHub", { accessTokenFactory: () => this.authService.getToken() })
            .configureLogging(signalR.LogLevel.Trace)
            .build();

        this.connection
            .start()
            .then(() => console.log('Connection started!'))
            .catch(err => console.log('Error while establishing connection :(' + err + ')'));

        this.connection.onclose(() => () => console.log('Connection closed!'))
        
    }

}