import { injectable, inject } from "inversify";
import { IApiService } from '../Shared/ApiService';
import SERVICE_IDENTIFIER from "../../Wires/Identifiers";
import { IHeader, HeaderModel } from "../../Redux/Reducers/HeaderTs";
import { Store, ActionCreator } from "redux";
import * as Api from "../../Constants/ApiUrlConstants";
import { IHeadRefreshAction } from "../../Redux/Reducers/Actions/HeadRefreshAction";
import IoCContainer from '../../Wires/Bootstrapper';

export interface IHeadService{
    refresh():void;
}

@injectable()
export default class HeadService implements IHeadService{
    private _apiService : IApiService;
    private _store : Store<any>;
    private _action : IHeadRefreshAction;
    constructor(){
        
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this._store = IoCContainer.get(SERVICE_IDENTIFIER.STORE);
        this._action = IoCContainer.get(SERVICE_IDENTIFIER.HEADER_ACTION);
    }

    refresh(): void {        
        this._apiService.get<HeaderModel>(Api.LoggedUserName,null,resp=>{                        
            this._action.RefreshHead(resp as HeaderModel);                                  
        },null);                
    }
    
}

