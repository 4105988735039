import * as React from 'react'

export interface IHandlerDelegate {
    (data: any): void;
}


export class TPContext {
    IsDrawerOpen : boolean
    OpenDrawer: () => void
}

export const DispatcherContext = React.createContext(new TPContext());