import * as React from 'react';
import {Redirect} from 'react-router-dom';

import {RouteComponentProps} from "react-router";
import { IApiService } from '../../Services/Shared/ApiService';
import container from '../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import * as Api from "../../Constants/ApiUrlConstants";
import { INotifyService } from '../../Services/Shared/NotifyService';
import { IAuthService } from '../../Services/Security/AuthServiceTs';

export class Logout extends React.Component<{}>
{
    private apiClient: IApiService;
    private authService: IAuthService;
    
    constructor(props) {
      super(props);
      this.apiClient = container.get(SERVICE_IDENTIFIER.API_SERVICE);    
      this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);    
    }

    componentWillMount(){     
      this.apiClient.get<any>(Api.Logout,null,(resp)=>
      {
        
        this.authService.signOutAndReload();
      },null);
    };

    render() {
      return (<Redirect to={'/login'} />);
    };
}

export default Logout;
