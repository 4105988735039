import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import IoCContainer from '../Wires/Bootstrapper';
import { IApiService } from "./Shared/ApiService";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { FilesystemVirtualeStateEditModel, FilesystemVirtualeUploadModel } from "../Models/FilesystemVirtualeModels";
import { IFormDataConverter } from "./Shared/FormDataConverter";
import { ModelBase } from "../Models/ModelBase";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface IFSVirtualeService {
    GetRootFolder(data: string): Promise<FilesystemVirtualeStateEditModel>
    GetById(data: string, foldersOnly: boolean): Promise<FilesystemVirtualeStateEditModel>
    Upload(data: FilesystemVirtualeUploadModel): Promise<ModelBase>
    GetFile(data: FilesystemVirtualeStateEditModel)
    Delete(id: string): Promise<ModelBase>
    Rename(data: FilesystemVirtualeStateEditModel): Promise<ModelBase>
    Move(data: FilesystemVirtualeStateEditModel): Promise<ModelBase>
}

@injectable()
export class FSVirtualeService implements IFSVirtualeService {

    _apiService: IApiService;
    _apiServiceExt: IApiServiceExt;
    _formDataConverter: IFormDataConverter

    constructor() {
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._formDataConverter = IoCContainer.get(SERVICE_IDENTIFIER.FORMDATA_CONVERTER);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.FSVIRTUALE
    }

    async GetRootFolder(data: string): Promise<FilesystemVirtualeStateEditModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetRootFolder, { Cliente: data })
        return resp
    }

    async GetById(data: string, foldersOnly: boolean = false): Promise<FilesystemVirtualeStateEditModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetById, { Id: data, FoldersOnly: foldersOnly })
        return resp
    }

    async Upload(data: FilesystemVirtualeUploadModel): Promise<ModelBase> {
        var input = this._formDataConverter.convert(data)
        const resp = await this._apiService.postAsync<any>(Api.UploadFile, input)
        return resp
    }

    async GetFile(data: FilesystemVirtualeStateEditModel) {        
        var resp = await this._apiService.getAsync<ModelBase>(Api.PrepareFile, { FileId: data.Id })
        var url = process.env.REACT_APP_BASEURL + Api.GetFile + "?Code=" + resp.Code
        window.open(url)
    }

    async Delete(id: string): Promise<ModelBase> {
        const resp = await this._apiServiceExt.getAsync<any>(Api.DeleteFile, { Id: id })
        return resp
    }

    async Rename(data: FilesystemVirtualeStateEditModel): Promise<ModelBase> {
        const resp = await this._apiServiceExt.postAsync<any>(Api.RenameFile, data)
        return resp
    }

    async Move(data: FilesystemVirtualeStateEditModel): Promise<ModelBase> {
        const resp = await this._apiServiceExt.postAsync<any>(Api.MoveFile, data)
        return resp
    }
}