import { ModelBase } from './ModelBase'

export class StatoFirma extends ModelBase {
    public IdTicket: string
    public Stato: string
    public FilesystemVirtuale_Id: string
    public LabelTasto: string
    public UrlBozza: string
    public UrlFirma: string
    public File_Id: string
}

export class CambioStatoFirmaInput {
    public FileId: string
    public Users: Array<string>
}