import * as React from 'react'
import { Button } from 'antd';

const ButtonGroup = Button.Group;

export const ToolbarItem: React.FC<any> = props => {

    const { name, description, isGroup, buttons, enabled, visible, onButtonClick, tipo, icon } = props;

    if (name) {
        var type = tipo ? tipo : "primary";
        return visible == true ? (
            <Button
                type={type}
                key={name}
                name={name}
                icon={icon}
                onClick={() => onButtonClick(props)}
                disabled={!enabled}>
                {description}
            </Button>
        )
            : null;
    }

    if (isGroup) {
        return (
            <ButtonGroup>
                {buttons.map(ToolbarItem)}
            </ButtonGroup>
        );
    }

    return null;
}