import * as React from 'react'
import {
    FlexibleWidthXYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries
} from 'react-vis';
import { ChartHintState } from './../../../Models/ChartHintState';
import { TTChartBaseComponent } from './../TTChartBaseComponent'
import { FatturatoModel } from '../../../Models/FatturatoModels';

export interface IFatturatoChartProps {
    previousYearColor: string
    currentYearColor: string
    previousYear: Array<FatturatoModel>
    currentYear: Array<FatturatoModel>
}

export class FatturatoChart extends TTChartBaseComponent<IFatturatoChartProps, ChartHintState<any>> {
    
    constructor(props) {
        super(props)

        this.state = new ChartHintState()
    }

    // Nota: L'anno è in riga perchè il grafico si presenterà in questo modo:
    // Novembre 2017 - Novembre 2018
    // Dicembre 2017 - Dicembre 2018
    // Gennaio 2018 - Gennaio 2019
    // Febbraio 2018 - Febbraio 2019
    // ...
    getHintLayout(hint: any) {
        return (
            <div>
                <div>{hint.x} {hint.year}</div>
                <div>{this.formatEuro(hint.y)}</div>
            </div>
        )
    }

    formatEuro = value => {
        let formatter = new Intl.NumberFormat("it-IT", {
            style: "currency",
            currency: "EUR"
        });
        return formatter.format(value)
    }

    render() {
        return (
            <FlexibleWidthXYPlot margin={{ bottom: 50, left: 60 }} xType="ordinal" height={350}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis tickLabelAngle={-30} />
                <YAxis />
                <VerticalBarSeries
                    color={this.props.previousYearColor}
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.previousYear}
                />
                <VerticalBarSeries
                    color={this.props.currentYearColor}
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.currentYear}
                />
                {this.state.hint ? this.buildHint() : null}
            </FlexibleWidthXYPlot>
        )
    }
}