import * as React from 'react'
import { Card, Row, Col } from 'antd'
import { TTTable } from '../TTTable';
import Search from 'antd/lib/input/Search';
import { ILicenzaClientiService } from '../../../Services/LicenzaClientiService';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { LicenzeProjection } from '../../../Models/LicenzeModels';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { BaseFormReactComponent } from '../BaseReactComponent';
import { ClienteProjection } from '../../../Models/ClienteProjection';


export interface IRicercaLicenzaClienti {
    clienti: (IdClienti: string[]) => void
    refresh: boolean
}

class State extends PagedBaseState<ClienteProjection> {
    selectedRowKeys: []
}

export class RicercaLicenzaClienti extends BaseFormReactComponent<IRicercaLicenzaClienti, State> {

    service: ILicenzaClientiService
    RigheLista = 10

    constructor(props) {
        super(props)

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.LICENZA_CLIENTI_SERVICE);
        this.state = {
            selectedRowKeys: [],
            Page: 1,
            Searcher: null,
            Data: {
                Total: 0,
                Data: []
            }
        }

        this.search(1)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.refresh != this.props.refresh && this.props.refresh != null) {
            // Resetto i clienti selezionati
            this.setState({ selectedRowKeys: [] })

            // Torno alla prima pagina
            this.search(1)
        }
    }


    search = (page: number) => {
        let searcher: SearcherBaseModel = {
            Righe: this.RigheLista,
            Pagina: page,
            FullText: this.state.Searcher
        }
        this.service.Search(searcher).then(it => this.setState({ Data: it, Page: page }))
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
        this.props.clienti(selectedRowKeys)
    };

    render() {
        const columns = [
            {
                title: 'Ragione sociale',
                dataIndex: 'Descrizione'
            },
            {
                title: 'Codice Fiscale',
                dataIndex: 'CodiceFiscale'
            },
            {
                title: 'Partita IVA',
                dataIndex: 'PartitaIva'
            },
            {
                title: 'Città',
                dataIndex: 'Citta'
            },
            {
                title: 'Tipo Contabilità',
                dataIndex: 'TipoContabilita'
            },
            {
                title: 'Liquidazione',
                dataIndex: 'Liquidazione'
            }
        ];
        return (
            <Card bordered={false} title="Seleziona clienti ai quali assegnare la licenza">
                <Row>
                    <Col offset={1} span={22}>
                        <Search
                            placeholder="Cerca cliente"
                            onChange={this.onChangeText}
                            name="Searcher"
                            onSearch={() => this.search(1)}
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <TTTable
                    columns={columns}
                    dataSource={this.state.Data.Data}
                    rowKey={it => it.Id}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys,
                        onChange: this.onSelectChange
                    }}
                    pagination={{
                        onChange: (page) => this.search(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                />
            </Card>
        )
    }
}