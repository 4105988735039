import * as React from 'react';
import { Row, Col, Card, List, Avatar } from 'antd';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteService } from '../../../Services/ClienteService';
import IoCContainer from '../../../Wires/Bootstrapper';

import { ClienteCover } from './ClienteCover'
import { ClienteAdempimenti } from './ClienteAdempimenti'
import { ClienteFatturato } from './ClienteFatturato'
import { ClienteNotifications } from './ClienteNotifications'
import { ClienteScadenze } from './ClienteScadenze'
import { ClienteUtenti } from './ClienteUtenti'
import { ModelBase } from '../../../Models/ModelBase';

const ComponentService: symbol = SERVICE_IDENTIFIER.CLIENTE_SERVICE;

export class PageClienteState {
    public IdCliente: string
}

export class PageCliente extends React.Component<any & IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, PageClienteState> {

    private _service: IClienteService;

    constructor(props) {
        super(props);
        this.state = this.props.componentData || new PageClienteState();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Clienti"));
        this._service.getToolbar().addButtonClick("DocCliente", this.onDocumentsClick);
        this._service.getToolbar().addButtonClick("CreateUser", this.onCreateUserClick);
        this._service.getToolbar().addButtonClick("BuyLicense", this.onBuyLicense);

    }

    componentDidMount() {
        const { match } = this.props;

        this.setState({ IdCliente: match.params.id });
    }

    onBuyLicense = () => {
        this._service.CompraLicenza(this.state.IdCliente);
    }

    onDocumentsClick = () => {
        this.props.history.push('/fs/' + this.state.IdCliente)
    }

    onCreateUserClick = () => {
        this.props.history.push('/ClienteUtenteEdit/' + this.state.IdCliente)
    }

    toggleBuyVisibility = (isVisible: boolean) => {
        this._service.getToolbar().renderButton("BuyLicense", !isVisible)
    }

    render() {
        return (
            <div>
                <Row type="flex" gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                        <ClienteCover clienteId={this.state.IdCliente} {...this.props} toggleBuyButton={this.toggleBuyVisibility} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} >
                        <ClienteNotifications clienteId={this.state.IdCliente} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <ClienteScadenze clienteId={this.state.IdCliente} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ClienteAdempimenti clienteId={this.state.IdCliente} {...this.props} />
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="last-card">
                            <ClienteFatturato clienteId={this.state.IdCliente} />
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="last-card">
                            <ClienteUtenti Cliente={this.state.IdCliente} {...this.props} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const Cliente = withRouter(TTComponent(PageCliente, 'Cliente', ComponentService));
export default Cliente;
