import * as React from 'react'
import { TTIcon } from './TTIcon'

export interface ITTUploadLayout {
    text?: string
}

export const TTUploadLayout: React.FC<ITTUploadLayout> = props => {
    let { text } = props
    if (text == null) {
        text = 'Clicca o trascina qui per caricare (max 1 file)'
    }

    return (
        <div>
            <p>
                <TTIcon icon="carica" style={{ fontSize: 45 }} />
            </p>
            <p>
                {text}
            </p>
        </div>
    )
}