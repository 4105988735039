import { ModelBase } from './ModelBase'

export class FatturatoModel {
    public x: string
    public y: number
    public year: number
}

export class FatturatoData extends ModelBase {
    public CurrentSerieColor: string
    public PreviousSerieColor: string
    public Current: Array<FatturatoModel>
    public Previous: Array<FatturatoModel>

    constructor() {
        super()
        this.Current = []
        this.Previous =[]
    }
}