import * as React from 'react';
import { List, Empty, Icon, Modal } from 'antd';
import { FileSystemWindow } from './FS/FileSystem';
import { FilesystemVirtualeStateEditModel } from './../../Models/FilesystemVirtualeModels'
import { TTUploadLayout } from './TTUploadLayout';

export interface IAttachmentSelectionProps {
    userId: string
    selectedItem: (item: FilesystemVirtualeStateEditModel) => void
}

export class AttachmentSelectionState {
    public attachments: Array<FilesystemVirtualeStateEditModel>;
    public selectionModalVisible: boolean
    public okButtonDisabled: boolean
    public SelectedItem: FilesystemVirtualeStateEditModel
}

export class AttachmentSelection extends React.Component<IAttachmentSelectionProps, AttachmentSelectionState> {
    constructor(props) {
        super(props);
        var state = new AttachmentSelectionState()
        state.okButtonDisabled = true
        this.state = state

    }

    empty() {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span />}>
                <a onClick={this.onSelectClick}>Seleziona File o Cartella</a>
            </Empty>
        );
    }

    openSelectionModal = () => {
        this.setState({ selectionModalVisible: true })
    }

    closeSelectionModal = () => {
        this.setState({ selectionModalVisible: false })
    }

    onSelectClick = () => {
        this.openSelectionModal()
    }

    onRemoveClick = () => {
        this.setState({ attachments: null });
    }

    handleSelectChange = (item: FilesystemVirtualeStateEditModel) => {
        if (item != null) {
            this.setState({ SelectedItem: item });
        }
        this.setState({ okButtonDisabled: item == null })
    }

    onOkClick = () => {
        this.closeSelectionModal()

        // Notifica padre della selezione
        this.props.selectedItem(this.state.SelectedItem)
        this.setState({ attachments: [this.state.SelectedItem] })
    }

    render() {
        let box = <span />
        if (!this.state.attachments) {
            // Mostra box se non c'è l'allegato
            box = <div className="ant-upload ant-upload-drag" style={{ padding: '16px 0'}} onClick={this.onSelectClick}>
                <TTUploadLayout text="Clicca per selezionare file o cartella" />
            </div>
        }

        let list = <span />
        if (this.state.attachments) {
            // Mostra lista allegati se c'è un allegato selezionato
            list = <List
                locale={{ emptyText: <span /> }}
                itemLayout="horizontal"
                dataSource={this.state.attachments}
                renderItem={item => (
                    <span>
                        <List.Item
                            actions={[<Icon type="close" onClick={this.onRemoveClick} />]}>
                            <Icon type={item.TipoRecord == "Directory" ? "folder" : "paper-clip"} />
                                <span className="ant-upload-list-item-name">{item.Code}</span>
                        </List.Item>
                    </span>
                )}
            />
        }

        return (
            <div>
                <Modal visible={this.state.selectionModalVisible}
                    title="Seleziona file o cartella"
                    onCancel={this.closeSelectionModal}
                    okText="Conferma selezione"
                    onOk={this.onOkClick}
                    okButtonProps={{ disabled: this.state.okButtonDisabled }}>
                    <FileSystemWindow clienteId={this.props.userId} selectionMode={true} selectedItem={this.handleSelectChange} />
                </Modal>
                {box}
                {list}
            </div>
        );
    }
}