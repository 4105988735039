import * as React from 'react'
import { Card } from 'antd';
import { PageHeaderToolbar } from './PageHeaderToolbar';
import { ToolbarProxy } from '../../Services/ToolbarService/ToolbarServiceTs';

export interface IContainerLayoutProps {
    onButtonClick(obj: any)
    onBackClick()
    boxed: boolean
    title: string
    toolbarData: ToolbarProxy
}

const ContainerLayout: React.FC<IContainerLayoutProps> = props => {

    return (
        props.boxed ?
            <div>
                <PageHeaderToolbar {...props} />
                <br />
                <div className="box box-default">
                    <div className="box-body">
                        {props.children}
                    </div>
                </div>
            </div>
            :
            <React.Fragment>
                <PageHeaderToolbar {...props} />
                <br />
                {props.children}
            </React.Fragment>
    );
}

export default ContainerLayout