import * as React from 'react'
import { FileSystemWindow } from '../FS/FileSystem'
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels';
import { SignButton } from './SignButton';
import { TTModal } from '../TTModal'
import { UserPicker } from './UserPicker';
import { Card, Button } from 'antd';
import { IFirmaService } from '../../../Services/FirmaService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { StatoFirma, CambioStatoFirmaInput } from '../../../Models/FirmaModels';
import * as SignState from "../../../Constants/SignConstants";
import { IFSVirtualeService } from '../../../Services/FSVirtualeService';

export class SignFSWrapperState {
    public data: StatoFirma
    public selectedFile: FilesystemVirtualeStateEditModel
    public modalVisible: boolean
    public users: Array<string>
}

export interface ISignFSWrapperProps {
    clienteId: string
}

export class SignFSWrapper extends React.Component<ISignFSWrapperProps, SignFSWrapperState> {

    service: IFirmaService
    TITLE: string = 'Attenzione';

    constructor(props) {
        super(props)

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.FIRMA_SERVICE)
        var state = new SignFSWrapperState()
        state.data = new StatoFirma()

        this.state = state
    }


    handleSelectChange = (item: FilesystemVirtualeStateEditModel) => {
        if (item == null || item.IsDirectory) {
            this.setState({ selectedFile: null })
            return
        }

        this.setState({ selectedFile: item })
        this.service.GetStatoByFile(item.Id).then(resp => this.setState({ data: resp }))
    }

    restartProcess = () => {
        TTModal.confirm(
        {
            title: 'Attenzione',
            content: 'La pratica verrà ripristinata, vuoi continuare?',
            okText: 'Si',
            cancelText: 'No',
            onOk: () => {
                var input: CambioStatoFirmaInput = {
                    FileId: this.state.selectedFile.Id,
                    Users: null
                }

                this.service.RiavviaProceduraFirma(input).then(resp => {
                    if (resp.IsValid) {
                        this.setState({ data: resp });
                        this.handlePostChangeState();
                    }
                })
            }
        })
    }

    changeState = () => {
        var firma = this.state.data
        if (firma.Stato == SignState.InizioProcesso) {
            this.setState({ modalVisible: true })
            return
        } else if (firma.Stato == SignState.Annullata) {
            this.restartProcess()
            return
        } else if (firma.UrlBozza) {
            window.open(firma.UrlBozza)
            firma.UrlBozza = ""
            return
        }

        this.callCambioStato([])
    }

    cancelState = () => {
        var input: CambioStatoFirmaInput = {
            FileId: this.state.selectedFile.Id,
            Users: null
        }

        this.service.AnnullaFirma(input).then(resp => {
            if (resp.IsValid)
                this.setState({ data: resp });
        })
    }

    hideModal = () => {
        this.setState({ modalVisible: false })
    }

    callCambioStato = (users: string[]) => {
        var input: CambioStatoFirmaInput = {
            FileId: this.state.selectedFile.Id,
            Users: users
        }

        this.service.CambiaStato(input).then(resp => {
            if (resp.IsValid) {
                this.setState({ data: resp });
                this.handlePostChangeState();
            }
        })
    }

    handlePostChangeState = () => {
        var firma = this.state.data

        if (firma.Stato == SignState.Bozza) {
            if (firma.UrlBozza) {
                window.open(firma.UrlBozza)
                firma.UrlBozza = ""
                return
            }
        } else if (firma.Stato == SignState.Firmata && firma.File_Id != "") {
            let fsService: IFSVirtualeService = IoCContainer.get(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE);
            fsService.GetFile(this.map(firma));
            return
        }
    }

    map = (source: StatoFirma) => {
        let dest: FilesystemVirtualeStateEditModel = new FilesystemVirtualeStateEditModel();
        dest.Id = source.FilesystemVirtuale_Id;
        return dest;
    }

    okModal = () => {
        this.callCambioStato(this.state.users)
    }

    getButtons = () => {
        let signButton = <SignButton type="primary" key="signButton" state={this.state.data.Stato}
            onClick={() => this.changeState()}>{this.state.data.LabelTasto}</SignButton>

        let cancelButton = <Button onClick={() => this.cancelState()} key="cancelButton">Annulla</Button>

        var firma = this.state.data
        if (firma.Stato == SignState.Annullata || firma.Stato == SignState.InizioProcesso) {
            return [signButton]
        }

        return [signButton, cancelButton]
    }

    render() {
        const { clienteId } = this.props;

        let signLayout = <div />
        if (this.state.selectedFile != null) {
            signLayout = <Card bordered={false} title="Firma Digitale" extra={this.getButtons()} />
        }

        return (
            <div>
                <TTModal visible={this.state.modalVisible} okText="Ok" cancelText="Annulla"
                    title="Selezione utenti"
                    closeModal={this.hideModal} do={this.okModal}>
                    <UserPicker clienteId={clienteId} selectedUsers={(users) => this.setState({ users })} />
                </TTModal>
                <FileSystemWindow clienteId={clienteId} selectedItem={this.handleSelectChange} />
                {signLayout}
                <br />
            </div>
        )
    }
}
