import  * as React from 'react';
import { Menu, Icon, Popover, List, Badge } from 'antd';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { IHeader } from '../../../Redux/Reducers/HeaderTs';
import {RouteComponentProps} from "react-router";
import * as ReactRedux from "react-redux";
import {asyncReactor} from 'async-reactor';
import { IApiService } from '../../../Services/Shared/ApiService';

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import container from '../../../Wires/Bootstrapper';
import { IHeadService } from '../../../Services/MenuService/HeadService';
import { bindActionCreators } from 'redux';
import * as actions from '../../../Redux/Reducers/Actions/ActionsTS';
import { IAuthService } from '../../../Services/Security/AuthServiceTs';
import * as Api from "../../../Constants/ApiUrlConstants";
import {Redirect} from 'react-router-dom';
import { LoginModel } from '../../../Models/LoginModel';
import { TTIcon } from '../../../app/Contents/TTIcon';
import { TPContext, DispatcherContext } from '../../../Services/Shared/Dispatcher-Context';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const PrintRole =(data:any,click:(c:any)=>void)=>{ 
  if (data!= null){
    return <Menu.Item key={data.Key}><a onClick={()=>click(data)}>{data.Value}</a></Menu.Item>
    }
}

export class NavRight extends React.Component<RouteComponentProps & IHeader,any>
 {
  private headService: IHeadService;
  private authService: IAuthService;
  private apiClient: IApiService;

  constructor(props){
    super(props);
    
    this.headService = container.get(SERVICE_IDENTIFIER.HEADER);  
    this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);    
    this.apiClient = container.get(SERVICE_IDENTIFIER.API_SERVICE);   
  }

  getRoles(){
    let items: Array<any> = new  Array<any>();
    
    if (this.props.roles!=null &&
        this.props.roles.length>0)
    {
        //console.log(this.props.roles.length);
        this.props.roles.map(c => items.push(PrintRole(c, () => null /*this.ChangeRoleClick*/)));
        items.push(<Menu.Item key="sep"><hr/></Menu.Item>);
    }
      
    return items;
  }
  componentDidMount(){ 
    
    if(this.authService.isAuthenticated())
      this.headService.refresh();       
  }

  getNotification() {
    return (
      <List
        itemLayout="horizontal"
        dataSource={this.props.notification}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={item.title}
              description={item.descrizione}
            />
          </List.Item>
        )}
      />
    );
  }

  getBadge() {
    const NotificheContainer = this.getNotification();  
    
    const {notification} = this.props;
    if (notification == null) return null;
    const BadgeComp = (
      <span>
        <Badge count={notification.length}>
          <TTIcon icon="notifica" />
        </Badge>
      </span>);
    return (
      <Popover
        placement="bottomRight"
        content={NotificheContainer}
        trigger="click"
        arrowPointAtCenter
      >
        {BadgeComp}
      </Popover>
    );
  }

  ChangeRoleClick=(item)=>{
    
      const param={Token:this.authService.getToken(),Role:item.Key};
   
      this.apiClient.post<any>(Api.ChangeRole,param,(resp)=>{        
          if (resp.IsValid) {
            let model = new LoginModel();
            model.UserName=resp.UserName;
            model.Token =resp.Token;
            this.authService.signOut(null);
            this.authService.signInAndReload(model);
          } 
      },null);     
  }

  LogOut=()=>{
    this.apiClient.get<any>(Api.Logout,null,(resp)=>
    {      
      this.authService.signOutAndReload();
    },null);
  }
  
  render() {
      
    const badgeComp = this.getBadge();

      return (
          <DispatcherContext.Consumer>
              {({ OpenDrawer }) => (
                  <div className="header-right-container">
                      {badgeComp}
                      <Menu mode="horizontal">
                          <SubMenu title={<span>{this.props.user}-{this.props.role}&nbsp;&nbsp;<TTIcon icon="user" style={{ fontSize: 20 }} /></span>}>
                              {this.getRoles()}
                              <Menu.Item key="logout"><a onClick={this.LogOut}>LogOut</a></Menu.Item>
                          </SubMenu>
                          <SubMenu title={<span onClick={OpenDrawer}><TTIcon icon="notifica" style={{ fontSize: 20 }} /></span>}></SubMenu>
                      </Menu>
                  </div>)}
              </DispatcherContext.Consumer>
    );
  }
}
const mapStateToProps = state => ({
 ...state.header
});

const mapDispatchToProps = dispatch => bindActionCreators(
  actions,
  dispatch,
);

export default ReactRedux.connect(mapStateToProps,mapDispatchToProps)(NavRight);