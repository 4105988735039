import * as React from 'react'
import { Col, Row, message, Card, Descriptions } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router';


import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteUtenteService } from '../../../Services/ClienteUtenteService';
import IoCContainer from '../../../Wires/Bootstrapper';

import { ClienteUtenteNotification } from './ClienteUtenteNotifications';
import { ClienteUtenteLoginLog } from './ClienteUtenteLoginLog';
import { ClienteUtenteBaseState } from '../../../Models/ClienteUtenteBaseState';
import { TTModal } from '../TTModal';
import moment from 'moment'

const ComponentService: symbol = SERVICE_IDENTIFIER.CLIENTE_UTENTE_SERVICE;

export class PageClienteUtenteState extends ClienteUtenteBaseState {
    public resetPwdModalVisible?: boolean
    public deleteUserModalVisible?: boolean
    public IdUtente: string
}

export class PageClienteUtente extends React.Component<any  & IStoreModel & RouteComponentProps & ITTProps , PageClienteUtenteState> {

    private _service: IClienteUtenteService;

    constructor(props) {
        super(props)

        this.state =  this.props.componentData || new PageClienteUtenteState()

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_UTENTE_SERVICE);
        //this._service.getToolbar().addBackClick(() => this.props.history.push("/Cliente/" + this.state.ClienteId));
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Clienti"));
        this._service.getToolbar().addButtonClick("DeleteUser", this.onDeleteUserClick);
        this._service.getToolbar().addButtonClick("EditUser", this.onEditUserClick);
        this._service.getToolbar().addButtonClick("ResetPwd", this.onResetPwdClick);
    }

    deleteUser = () => {
        this._service.DeleteUser(this.state).then(resp => {
            if (resp.IsValid == true) this.props.history.push("/Cliente/" + this.state.ClienteId)
        })
    }

    resetPwd = () => {
        this._service.ResetPwd(this.state.IdUtente)
    }

    onDeleteUserClick = () => {
        this.openDeleteUserModal()
    }

    onEditUserClick = () => {
        this.props.history.push('/ClienteUtenteEdit/' + this.state.ClienteId + '/'  + this.state.IdUtente)
    }

    onResetPwdClick = () => {
        this.openResetPwdModal()
    }

    // Pop Reset Pwd
    openResetPwdModal = () => {
        this.setState({ resetPwdModalVisible: true })
    }

    closeResetPwdModal = () => {
        this.setState({ resetPwdModalVisible: false })
    }

    // Pop eliminazione utente
    openDeleteUserModal = () => {
        this.setState({ deleteUserModalVisible: true })
    }

    closeDeleteUserModal = () => {
        this.setState({ deleteUserModalVisible: false })
    }

    componentDidMount() {
        const { match } = this.props;
        this.setState({ ClienteId: match.params.IdCliente, IdUtente: match.params.id, Id: match.params.id });
    }

    formatDate = (date: Date) => {
        return moment(date).format('LL')
    }

    render() {
        // utilizzare secondRow quando le richieste saranno sganciate dagli adempimenti
        var secondRow = <div>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <ClienteUtenteNotification />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <ClienteUtenteLoginLog userId={this.state.IdUtente} />
            </Col>
        </div>

        var tempRow =
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <ClienteUtenteLoginLog userId={this.state.IdUtente} />
            </Col>

        var title = this.state.Nome + " " + this.state.Cognome
        return (
            <div>
                <TTModal visible={this.state.resetPwdModalVisible}
                    title="Reset Password" cancelText="Annulla"
                    closeModal={this.closeResetPwdModal} do={this.resetPwd}>
                    Resettare la password del cliente?
                </TTModal>
                <TTModal visible={this.state.deleteUserModalVisible}
                    title="Eliminazione Utente" cancelText="Annulla"
                    closeModal={this.closeDeleteUserModal} do={this.deleteUser}>
                    Eliminare l'utente?
                </TTModal>
                <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} >
                        <Card bordered={false} title={title}>
                            <Descriptions column={2} layout="vertical">
                                <Descriptions.Item label="Username">{this.state.Username}</Descriptions.Item>
                                <Descriptions.Item label="Email">{this.state.Email}</Descriptions.Item>
                                <Descriptions.Item label="Codice Fiscale">{this.state.CodiceFiscale}</Descriptions.Item>
                                <Descriptions.Item label="Telefono Cellulare">{this.state.Telefono}</Descriptions.Item>
                                <Descriptions.Item label="Codice Paese di Residenza">{this.state.PaeseResidenza}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <Card bordered={false} title="Documento Identificativo">
                            <Descriptions column={2} layout="vertical">
                                <Descriptions.Item label="Tipo documento">Carta d'identità</Descriptions.Item>
                                <Descriptions.Item label="Ente di rilascio">Comune</Descriptions.Item>
                                <Descriptions.Item label="Data rilascio">{this.formatDate(this.state.RilascioDocumento)}</Descriptions.Item>
                                <Descriptions.Item label="Data scadenza">{this.formatDate(this.state.ScadenzaDocumento)}</Descriptions.Item>
                                <Descriptions.Item label="Codice Documento">{this.state.CodiceDocumento}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    {tempRow}
                </Row>
            </div>
        )
    }
}

const ClienteUtente = withRouter(TTComponent(PageClienteUtente, 'Cliente Utente', ComponentService));
export default ClienteUtente;