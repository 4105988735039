
import { MenuData } from './MenuMock';
import { MenuData2 } from './MenuMockLv2';
import { injectable, inject } from 'inversify';
import axios from 'axios'
import * as apis from '../../Constants/ApiUrlConstants'
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IApiService } from '../Shared/ApiService';
import { IMenuModel } from '../ToolbarService/IMenuItems';
import MenuItem from 'antd/lib/menu/MenuItem';
import { IAuthService } from '../Security/AuthServiceTs';
import IoCContainer from '../../Wires/Bootstrapper';

export interface IMenuService {  
  getMenu() : IMenuModel | {};
}


@injectable()
export default class MenuService implements IMenuService {
  apiClient: IApiService;
  authService : IAuthService;

  public  constructor()
  {
      this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
      this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);
  }
  async getMenu() {
    let resp:IMenuModel = await this.apiClient.getAsync<IMenuModel>(apis.Menu,null);
    return resp;
      //.then((menu:IMenuItems) => success (menu));
  } 
}