import * as React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'

class SingleSelectTable {
    public SelectedKeys: string[]
}

export interface ITTSingleSelectTable<T> {
    onClick?: (arg: T) => void;
    onDoubleClick?: (arg: T) => void;
}

export class TTSingleSelectTable<T> extends React.Component<TableProps<T> & ITTSingleSelectTable<T>, SingleSelectTable> {

    constructor(props) {
        super(props)

        this.state = {
            SelectedKeys: []
        }
    }

    // Aggiornamento dell'elemento selezionato
    onSelectChange = selectedRow => {
        this.setState({ SelectedKeys: [selectedRow] });
    };

    onTableRowClick = (item) => {
        this.onSelectChange(item.Id)
        this.props.onClick(item)
    }

    onTableRowDoubleClick = (item) => {
        this.props.onDoubleClick(item)
    }

    // Definizione comportamento sulla singola riga
    onTableRow = (item, rowIndex) => {
        return {
            onClick: () => this.onTableRowClick(item),
            onDoubleClick: () => this.onTableRowDoubleClick(item),
        };
    }

    render() {
        return (
            <Table onRow={this.onTableRow} rowSelection={{ selectedRowKeys: this.state.SelectedKeys }} {...this.props} />
        )
    }
}