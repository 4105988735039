import * as React from 'react'
import { Form, Input } from 'antd'
import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTModal, IModalProps } from '../TTModal';
import Modal, { ModalProps } from 'antd/lib/modal';
import { FormComponentProps } from 'antd/lib/form/Form';

export class ModalRenameFileState {
    Name: string
}

export interface IRenameForm {
    currentName: string
}

class RenameFileForm extends BaseFormReactComponent<IModalProps & ModalProps & FormComponentProps & IRenameForm, ModalRenameFileState> {
    constructor(props) {
        super(props)

        this.state = {
            Name: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentName != this.props.currentName) {
            this.setState({ Name: this.props.currentName })
        }
    }

    closeModal = () => {
        this.props.form.resetFields();
        this.props.closeModal();
    }

    onCreate = () => {
        this.props.form.validateFields((error, values) => {
            if (error == null) {
                this.props.do(this.state);
                this.closeModal()
            }
        });
    }

    render() {
        return (
            <Modal title="Rinomina file" visible={this.props.visible} onCancel={this.closeModal} onOk={this.onCreate}>
                <Form>
                    <Form.Item label="Nome">
                        {this.getRequiredFieldInit(this.props.currentName, <Input size="small" onChange={this.onChangeText} name="Name" />)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export const ModalRenameFile = Form.create<IModalProps & ModalProps & FormComponentProps & IRenameForm>({ name: "form" })(RenameFileForm)