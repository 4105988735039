import * as React from 'react'
import { Card } from 'antd'

import { FatturatoChart } from './FatturatoChart'
import { FatturatoData } from '../../../Models/FatturatoModels' 
import { IClienteService } from '../../../Services/ClienteService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps';

export class ClienteFatturato extends React.Component<IClienteBaseProps, FatturatoData> {

    private service: IClienteService;

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this.state = new FatturatoData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clienteId != this.props.clienteId) {
            this.service.GetClienteFatturato(this.props.clienteId).then(resp => this.setState(resp))
        }
    }

    render() {
        return (
            <Card bordered={false} title="Fatturato" className="card-full-height">
                <FatturatoChart
                    previousYearColor={this.state.PreviousSerieColor}
                    currentYearColor={this.state.CurrentSerieColor}
                    previousYear={this.state.Previous}
                    currentYear={this.state.Current} />
            </Card>
        )
    }
}