import * as React from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Reducers/Actions/ActionsTS';
import { IApiService } from '../../Services/Shared/ApiService';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import IoCContainer from '../../Wires/Bootstrapper';

import { RouteComponentProps, Route } from "react-router";
import { withRouter } from "react-router-dom";
import { INotifyService } from '../../Services/Shared/NotifyService';
import * as Api from "../../Constants/ApiUrlConstants";
import { ILoginModel, LoginModel, ChangePasswordModel } from '../../Models/LoginModel';
import { IAuthService } from '../../Services/Security/AuthServiceTs';
import { FormComponentProps } from 'antd/lib/form';



export class ChangePassword extends React.Component<any & RouteComponentProps & FormComponentProps, ChangePasswordModel>
{
    private apiClient: IApiService
    private notifyService: INotifyService
    private authService: IAuthService

    constructor(props) {
        super(props);
        this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this.notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);

        this.state = new ChangePasswordModel();
    }

    onChangeText = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let data = this.state;
        data[name] = value;
        this.setState(data);

        this.props.form.setFieldsValue({
            NewPassword: this.state.NewPassword,
            Password: this.state.PassWord
        });
    }

    login = () => {
        let { match } = this.props;
        this.apiClient.postToken<any>(Api.RestorePassword, { NewPassword: this.state.NewPassword }, match.params.token,
            (resp) => {
                if (resp.IsValid == true) {
                    debugger
                    let model = new LoginModel();
                    model.PassWord = this.state.NewPassword;
                    model.Token = match.params.token;
                    this.authService.signInAndReload(model);
                }
            },
            (err) => {
                this.notifyService.error("Errore", err);
            }
        );

        // axios.get(Api.Login, {params: {username: this.state.user, password: this.state.password}})
        //   .then((resp) => {
        //     
        //     if (resp.data.IsValid == true) {
        //       authService.signIn({user: this.state.user, token: resp.data.Token},
        //         () => {
        //           this.props.setLogged(true);
        //           this.props.changeUser(this.state.user);
        //         });
        //     } else {
        //       alert('Errore');
        //     }
        //   });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('NewPassword')) {
            callback('Le password inserite non sono uguali!');
        } else {
            callback();
        }
    };

    doLogin = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) this.login()
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const FormItem = Form.Item;
        const { target } = this.props.location.state || { target: { pathname: '/' } };

        if (this.authService.isAuthenticated()) {
            return (<Redirect to={target} />);
        }

        return (
            <div>
                <Form className="login-form">
                    <FormItem hasFeedback>
                        {getFieldDecorator('NewPassword', {
                            rules: [{ required: true, message: 'Inserisci la nuova password' }],
                        })(
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                name="NewPassword"
                                type="Password"
                                placeholder="Nuova Password"
                                onChange={this.onChangeText} />
                        )}
                    </FormItem>
                    <FormItem hasFeedback>
                        {getFieldDecorator('Password', {
                            rules: [{ required: true, message: 'Inserisci password' },
                                {
                                    validator: this.compareToFirstPassword,
                                }],
                        })(
                            <Input.Password
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                name="PassWord"
                                type="Password"
                                onChange={this.onChangeText}
                                placeholder="Password" />
                        )}
                    </FormItem>
                    <br />
                    <Button type="primary" htmlType="submit" className="submit-button" onClick={this.doLogin}>Cambia Password</Button>
                </Form>
            </div>
        );
    };

}



const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => bindActionCreators(
    actions,
    dispatch,
);

const WrappedChangePasswordFormConnect = withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChangePassword)));
export default WrappedChangePasswordFormConnect; 