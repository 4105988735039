import * as admin from 'firebase-admin'
import * as firebase from "firebase/app"
import "firebase/messaging"
import { injectable } from "inversify"

import * as FirebaseConstants from '../../Constants/FirebaseConstants'
import SERVICE_IDENTIFIER from "../../Wires/Identifiers"
import IoCContainer from '../../Wires/Bootstrapper'

import { IDeviceService } from '../DeviceService'
import { DeviceTokenInputModel } from "../../Models/DeviceTokenInputModel"

export interface IFcmServiceHandler {
    requestNotifyPerms()
}

@injectable()
export class FcmServiceHandler implements IFcmServiceHandler {

    messaging: firebase.messaging.Messaging
    deviceService: IDeviceService

    constructor() {
        this.deviceService = IoCContainer.get(SERVICE_IDENTIFIER.DEVICE_SERVICE);

        // Initialize Firebase
        firebase.initializeApp(FirebaseConstants.firebaseConfig)

        // Setup cloud messaging
        this.messaging = firebase.messaging()
        this.messaging.usePublicVapidKey(FirebaseConstants.publicKey)
        this.messaging.onTokenRefresh(() => this.getAndSaveToken())
        this.messaging.onMessage(msg => this.handleMessage(msg))

        // Se onMessage non funziona, è possibile debuggare l'evento nel seguente modo:
        // navigator.serviceWorker.addEventListener("message", message => console.log(message));
    }

    protected handleMessage(message: admin.messaging.MessagingPayload) {
        // Todo
        console.log(message)
    }

    public requestNotifyPerms = () => {
        Notification.requestPermission().then(permission => {
            if (permission == 'granted') {
                console.log('Notification permission granted.');

                this.getAndSaveToken();
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    }

    protected getAndSaveToken = () => {
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        this.messaging.getToken().then(currentToken => {
            if (currentToken) {
                console.log(currentToken)

                this.sendTokenToServer(currentToken);
            } else {
                // Show permission request.
                console.log('No Instance ID token available. Request permission to generate one.')
            }
        }).catch(err => {
            console.log('An error occurred while retrieving token. ', err)
        });
    }

    protected sendTokenToServer = (token: string) => {
        var model: DeviceTokenInputModel = {
            DeviceToken: token,
            DeviceType: 3, // Web
            Model: navigator.userAgent
        }

        this.deviceService.PostDeviceInfo(model)
    }
}