import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { StatoFirma, CambioStatoFirmaInput } from "../Models/FirmaModels";

export interface IFirmaService extends IBaseComponentService {
    GetStatoByFile(data: string): Promise<StatoFirma>
    CambiaStato(data: CambioStatoFirmaInput): Promise<StatoFirma>
    AnnullaFirma(data: CambioStatoFirmaInput): Promise<StatoFirma>
    RiavviaProceduraFirma(data: CambioStatoFirmaInput): Promise<StatoFirma>
}

@injectable()
export class FirmaService extends BaseComponentService implements IFirmaService {

    RenderApi: string = ''
    _apiService: IApiService;

    constructor() {
        super()
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.FIRMA;
    }

    async GetStatoByFile(data: string): Promise<StatoFirma> {
        const resp = await this._apiService.getAsync<any>(Api.GetStatoFirmaByFile, { FileId: data})
        return resp
    }

    async CambiaStato(data: CambioStatoFirmaInput): Promise<StatoFirma> {
        const resp = await this._apiService.postAsync<any>(Api.CambiaStatoFirma, data)
        return resp
    }

    async AnnullaFirma(data: CambioStatoFirmaInput): Promise<StatoFirma> {
        const resp = await this._apiService.postAsync<any>(Api.AnnullaFirma, data)
        return resp
    }

    async RiavviaProceduraFirma(data: CambioStatoFirmaInput): Promise<StatoFirma> {
        debugger;
        const resp = await this._apiService.postAsync<any>(Api.RiavviaProceduraFirma, data)
        return resp
    }
}