import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";
import { ClienteProjection } from "../Models/ClienteProjection";
import { GetClientiByGruppiSearcher, GetClientiByAdempimentoSearcher } from "../Models/GetClientiByGruppoSearcher";
import { ClienteSearcher } from "../Models/UtentiSearcher";
import { Customer } from "../Models/AdempimentoCustomersState";
import { ClientiProjection } from "../Models/ClientiState";
import { UtentiProjection } from "../Models/UtentiProjection";
import { RichiesteProjection } from "../Models/RichiesteModels";
import { ScadenzeProjection } from "../Models/ScadenzeProjection";
import { FatturatoData } from "../Models/FatturatoModels";
import { AdempimentiClienteModel } from '../Models/AdempimentiClienteModels'
import { ModelBase } from "../Models/ModelBase";
import { IApiServiceExt } from "./Shared/ApiServiceExt";
import { StatoLicenzaModel } from "../Models/LicenzeModels";

export interface IClienteService extends IBaseComponentService {
    GetClientiByGruppi(searcher: GetClientiByGruppiSearcher): Promise<ProjectionPagedModel<ClienteProjection>>
    GetClientiByAdempimento(searcher: GetClientiByAdempimentoSearcher): Promise<ProjectionPagedModel<Customer>>
    GetCliente(idcliente: string): Promise<ClientiProjection>
    GetClienteUtenti(searcher: ClienteSearcher): Promise<ProjectionPagedModel<UtentiProjection>>
    GetClienteRichieste(searcher: ClienteSearcher): Promise<ProjectionPagedModel<RichiesteProjection>>
    GetClienteScadenze(searcher: ClienteSearcher): Promise<ProjectionPagedModel<ScadenzeProjection>>
    GetClienteFatturato(data: string): Promise<FatturatoData>
    GetClienteAdempimenti(data: string): Promise<AdempimentiClienteModel>
    CompraLicenza(clienteId: string): Promise<ModelBase>
    CheckLicenseByCliente(clienteId: string): Promise<StatoLicenzaModel>
}

@injectable()
export class ClienteService extends BaseComponentService implements IClienteService {

    RenderApi: string = '';
    _apiService: IApiService;
    _apiServiceExt: IApiServiceExt;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CLIENTE;
    }


    async GetClientiByGruppi(searcher: GetClientiByGruppiSearcher): Promise<ProjectionPagedModel<ClienteProjection>> {        
        const resp = await this._apiService.postAsync<any>(Api.GetClientiByGruppi, searcher)
        return resp
    }
    async GetClientiByAdempimento(searcher: GetClientiByAdempimentoSearcher): Promise<ProjectionPagedModel<Customer>> {
        const resp = await this._apiService.postAsync<any>(Api.GetClientiByAdempimento, searcher)
        return resp
    }



    async GetCliente(idcliente: string): Promise<ClientiProjection> {
        const resp = await this._apiService.getAsync<any>(Api.GetCliente, { idcliente: idcliente })
        return resp
    }

    async GetClienteUtenti(searcher: ClienteSearcher): Promise<ProjectionPagedModel<UtentiProjection>> {
        const resp = await this._apiService.getAsync<any>(Api.GetClienteUtenti, searcher)
        return resp
    }

    async GetClienteRichieste(searcher: ClienteSearcher): Promise<ProjectionPagedModel<RichiesteProjection>> {
        const resp = await this._apiService.getAsync<any>(Api.GetRichiesteByCliente, searcher)
        return resp
    }

    async GetClienteScadenze(searcher: ClienteSearcher): Promise<ProjectionPagedModel<ScadenzeProjection>> {
        const resp = await this._apiService.getAsync<any>(Api.GetScadenzeByCliente, searcher)
        return resp
    }

    async GetClienteFatturato(data: string): Promise<FatturatoData> {
        const resp = await this._apiService.getAsync<any>(Api.GetClienteFatturato, { ClienteId: data })
        return resp
    }

    async GetClienteAdempimenti(data: string): Promise<AdempimentiClienteModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetClienteAdempimenti, { ClienteId: data })
        return resp
    }

    async CompraLicenza(clienteId: string): Promise<ModelBase> {
        return await this._apiServiceExt.getAsync<ModelBase>(Api.CompraLicenzaCliente, { clienteId });
    }

    async CheckLicenseByCliente(clienteId: string): Promise<StatoLicenzaModel> {
        return await this._apiService.getAsync<StatoLicenzaModel>(Api.CheckLicenseByCustomer, { clienteId });
    }
}