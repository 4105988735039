import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import IoCContainer from '../Wires/Bootstrapper';
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IBaseComponentService, BaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { SearcherBaseModel } from "../Models/SearcherBaseModel";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";
import { LicenzeProjection, BuyOutputModel } from "../Models/LicenzeModels";
import { PagedBaseState } from "../Models/PagedBaseState";

export interface ILicenzeService extends IBaseComponentService {
    CompraLicenza(): Promise<BuyOutputModel>
    Search(search: SearcherBaseModel): Promise<ProjectionPagedModel<LicenzeProjection>>
    Render(): Promise<PagedBaseState<LicenzeProjection>>
}

@injectable()
export class LicenzeService extends BaseComponentService implements ILicenzeService {

    RenderApi = Api.RenderLicenze
    apiService: IApiService

    constructor() {
        super()
        this.apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.LICENZE;
    }

    async Render(): Promise<PagedBaseState<LicenzeProjection>> {
        return await this.apiService.getAsync<PagedBaseState<LicenzeProjection>>(Api.RenderLicenze, {});
    }

    async CompraLicenza(): Promise<BuyOutputModel> {
        return await this.apiService.getAsync<BuyOutputModel>(Api.CompraLicenzaTenant, {});
    }

    async Search(search: SearcherBaseModel): Promise<ProjectionPagedModel<LicenzeProjection>> {
        return await this.apiService.postAsync<ProjectionPagedModel<LicenzeProjection>>(Api.SearchLicenze, search);
    }
}