import { Layout, Drawer, notification } from 'antd';
import * as React from 'react';
import * as ReactRedux from "react-redux";
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import moment from 'moment'
import 'moment/locale/it'

import './../../src/styles/bootstrap.scss';
import 'antd/dist/antd.less';
import './../../src/styles/antd.less';
import 'react-vis/dist/style.css';

// custom
import './../../src/styles/layout.scss';
import './../../src/styles/theme.scss';
import './../../src/styles/ui.scss';
import './../../src/styles/app.scss';



import AppHeader from './../Shared/Layout/Header';
import AppFooter from './../Shared/Layout/Footer';
import AppSidebar from './../Shared/Layout/Sidebar';
import './../../src/styles/custom.scss';


import Notification from './Notification/index';
import Login from '../Shared/Login/LoginTs';

import ErrorPage from '../Shared/ErrorPages/Error'

import * as actions from '../Redux/Reducers/Actions/ActionsTS';
import Logout from '../Shared/Login/LogoutTs';
import { IAppLayoutService } from '../Services/Shared/AppLayout';
import container from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import * as Dispatcher  from './Contents/Dispatcher';
import { IMenuModel, IMenu } from '../Services/ToolbarService/IMenuItems';
import { IAuthService } from '../Services/Security/AuthServiceTs';
import { TPContext, DispatcherContext } from '../Services/Shared/Dispatcher-Context';
import { RichiesteDrawer } from './Contents/RichiesteDrawer';
import { withRouter } from 'react-router-dom';
import { ISignalRServiceHandler } from '../Services/Shared/SignalRServiceHandler';

import IoCContainer from '../Wires/Bootstrapper'
import { BackofficeResponseModel, ChannelLicenseMessageModel } from '../Models/SignalRResponseModel';
import { IFcmServiceHandler } from '../Services/Shared/FcmServiceHandler';

import { TTRedirect } from './../Shared/Redirect/Redirect'

interface IAppLayoutState {
    menu: IMenuModel;
    context: TPContext
}

const { Header, Footer, Content } = Layout;

const RenderRoute = (data: IMenu) => {
    const CP = Dispatcher[data.componentKey];
    if (CP == null)
        throw new Error('Creare l\'export per il componente ' + data.componentKey + ' in Dispatcher.Ts');

    return <CP />
}

const PrintRoute = (data: IMenu) => {
    if (data.menu != null && data.menu.length>0)
        return data.menu.map(PrintRoute)

    if (data.route != null)
        return <Route key={data.id} path={data.route} exact={data.route == "/"} render={() => RenderRoute(data)} />
}

export class NotificationClass {
    message: string
    title: string
    icon: string
    link:string
}

export class AppLayout extends React.Component<any, IAppLayoutState> {

    service: IAppLayoutService;
    authService : IAuthService;
  
    signalRService: ISignalRServiceHandler;
    fcmService: IFcmServiceHandler;


    constructor(props:any) {
        super(props);
        this.service = container.get(SERVICE_IDENTIFIER.APPLAYOUT_SERVICE);  
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);  


        this.state = {
            menu: { menu: [] },
            context: new TPContext()
        };
    }

    OpenDrawer = () => {
        this.setState({ context: { ...this.state.context, IsDrawerOpen: true } });
    }

    async componentDidMount() {

        
        moment.locale('it')
        if (this.authService.isAuthenticated()) {
            //const connection = new signalR.HubConnectionBuilder()
            //    .withUrl(process.env.REACT_APP_BASEURL.replace("/api", "") + "/NotificationHub", { accessTokenFactory: () => this.authService.getToken() })
            //    //.configureLogging(signalR.LogLevel.Trace)
            //    .build();

            let resp = await this.service.getMenuForRoute();
            debugger;
            //this.setState({ menu: resp , connection: connection },
            //    () => {
            //        debugger
            //        this.state.connection
            //            .start()
            //            .then(() => console.log('Connection started!'))
            //            .catch(err => console.log('Error while establishing connection :(' + err + ')'));

            //        this.state.connection.onclose(() => () => console.log('Connection closed!'))
            //        this.state.connection.on('ResponseRecieved', (data) => {
            //            if (this.context.delegates['ResponseRecieved'] !=null )this.context.delegates['ResponseRecieved'](data);
            //        });
            //    });
            this.setState({ menu: resp, context: {OpenDrawer: this.OpenDrawer, IsDrawerOpen:false }});

            // Setup SignalR
            this.signalRService = IoCContainer.get(SERVICE_IDENTIFIER.SIGNALR);
            this.signalRService.AddEventHandlerFor("SendToast", this.onToastRecieved)

            // Setup Firebase Cloud Messaging
            this.fcmService = IoCContainer.get(SERVICE_IDENTIFIER.FCM);
            this.fcmService.requestNotifyPerms();
        }
    }

    componentWillUnmount() {
        this.signalRService.RemoveEventHandlerFor("SendToast", this.onToastRecieved)
    }

    openNotification = (item: NotificationClass) => {
        notification.open({
            message: item.message,
            description: item.title,
            placement: 'bottomRight',
            onClick: () => {
                this.props.history.push(item.link);
            },
        });
    };


    onToastRecieved = (type: string, data: string) => {
        let message = new NotificationClass()
        switch (type) {
            case "Response": {
                let datar = JSON.parse(data) as BackofficeResponseModel;
                message.message = datar.Testo;
                message.title = datar.Titolo;
                message.link = datar.Link
                break;
            }
            case "LicenseReceived": {
                let datar = JSON.parse(data) as ChannelLicenseMessageModel;
                message.message = datar.Titolo;
                message.title = datar.Descrizione;
                message.link = datar.Link
                break;
            }
            default:
        }
        this.openNotification(message);
    }

    onDrawerClose = () => {
        this.setState({ context: {...this.state.context, IsDrawerOpen: false } });
    }
  
    render() {

        if (this.props.error.isError)
            return (
                <Layout id="app-main-layout" className="ant-layout-has-sider">
                    <Layout>
                        <Layout>
                            <Content className="app-content">
                                <ErrorPage />
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            )

        
        return (
            this.authService.isAuthenticated() ?
                <DispatcherContext.Provider value={this.state.context}>
                    <div className="full-height fixed-header">
                        <Drawer visible={this.state.context.IsDrawerOpen}
                            closable={false}
                            width={400}
                            onClose={this.onDrawerClose}>
                            <RichiesteDrawer {...this.props} />
                        </Drawer>
                        <Layout id="app-main-layout" className="ant-layout-has-sider">
                            <AppSidebar />
                            <Layout>
                                <Header className="app-header">
                                    <AppHeader />
                                </Header>
                                <Layout>
                                    <Content className="app-content">
                                        {
                                            <React.Fragment>
                                                {this.state.menu.menu.map(PrintRoute)}

                                                <Route path="/Redirect/:id" component={TTRedirect} />
                                                <Route path="/Notification" component={Notification} />
                                                <Route path="/login" component={Login} />
                                                <Route path="/logout" component={Logout} />
                                            </React.Fragment>
                                        }
                                    </Content>
                                    <Footer className="app-footer"> <AppFooter /> </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </div>
                </DispatcherContext.Provider>
                :
                <div>
                    <Layout id="app-main-layout" className="ant-layout-has-sider">
                        <Layout>
                            <Layout>
                                <Content className="app-content">
                                    <Route path="/" component={Login} />
                                    <Route path="/Redirect/:id" component={TTRedirect} />
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </div>
        );
    }
}
AppLayout.contextType = DispatcherContext;
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => bindActionCreators(
  actions,
  dispatch,
);

export default withRouter(ReactRedux.connect(mapStateToProps, mapDispatchToProps)(AppLayout));