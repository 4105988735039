import Cookies from 'universal-cookie';
import { injectable } from 'inversify';
import { ILoginModel } from '../../Models/LoginModel';

const cookies = new Cookies();

export interface IAuthService {
    isAuthenticated(): boolean;
    getToken(): string;
    setToken(token: string)
    signInAndReload(model: ILoginModel): void;
    signIn(model: ILoginModel, postLogin: () => void): void;
    signOut(postLogout: () => void): void;
    signOutAndReload(): void;
    restart(): void;
}


@injectable()
export default class AuthService implements IAuthService
{
    isAuthenticated(): boolean {
        return cookies.get('auth') != null;
    }

    getToken(): string {
        return cookies.get('auth');
    }

    setToken(token: string) {
        cookies.set('auth', token, { path: '/' });
    }

    signInAndReload(model: ILoginModel): void {
        this.signIn(model, () => { this.restart(); });
    }

    signIn(model: ILoginModel, postLogin: () => void): void {
        this.setToken(model.Token)
        postLogin();
    }

    signOut(postLogout: () => void): void {
        cookies.remove('auth');
        if (postLogout != null) postLogout();
    }

    signOutAndReload(): void {
        this.signOut(() => { this.restart(); });
    }

    restart(): void {
        window.location.href = '/';
    }
}

