import * as React from 'react'
import { Col, Row, Card, Form, Input, DatePicker } from 'antd'
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';

import { IAdempimentoEditService } from '../../../Services/AdempimentoEditService';

import { AdempimentoEditAttivita } from './AdempimentoEditAttivita'

import { GruppoModel } from '../Circolari/PageCircolareEdit';
import { ListaCustomers } from '../Shared/ListaCustomers';
import { ClienteProjection } from '../../../Models/ClienteProjection';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { BaseFormReactComponent } from '../BaseReactComponent';
import moment from 'moment';
import { AdempimentoActivity } from '../../../Models/IAdempimentoActivities';
import locale from 'antd/es/date-picker/locale/it_IT';
import { TTSelect } from '../TTSelect';
import { FormComponentProps } from 'antd/lib/form';

const ComponentService: symbol = SERVICE_IDENTIFIER.ADEMPIMENTO_EDIT_SERVICE;

export class AdempimentoState  {    
    public Id: string
    public Titolo: string
    public Descrizione: string
    public Note: string
    public Scadenza: Date;
    public Gruppi: Array<GruppoModel>;
    public GruppiSource: Array<GruppoModel>;
    public Clienti: Array<ClienteProjection>;
    public Attivita: Array<AdempimentoActivity>;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        lg: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        lg: { span: 18 },
    }
}

export class PageAdempimentoEdit extends BaseFormReactComponent<IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, AdempimentoState> {

    private _service: IAdempimentoEditService

    constructor(props) {
        super(props)
        this.state = this.props.componentData || new AdempimentoState();

        this.gruppiChange = this.gruppiChange.bind(this);
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTO_EDIT_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Adempimenti"));
        this._service.getToolbar().addButtonClick("Salva", () => this.saveAdempimento());
        this._service.getToolbar().renderButton("ModificaAdempimento", true);
        this._service.getToolbar().renderButton("CopiaAdempimento", true);
    }

    gruppiChange(groups)
    {
        this.setState({ Gruppi: this.getListOf(groups) })
    }

    saveAdempimento = () => {
        this.validateAndSave(() => this._service.SaveAdempimento(this.state))
    }

    onAttivitaUpdate = (obj: Array<AdempimentoActivity>) => {
        this.setState({ Attivita: obj })
    }

    render() {
        const selectedGroups = this.state.Gruppi.map(item => item.Id)

        return (
            <div>
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 16 }}>
                        <Row>
                            <Col>
                                <Card bordered={false} title="Adempimento">
                                    <Form onSubmit={async (props) => null}>
                                        <FormItem {...formItemLayout} label="Descrizione">
                                            {this.getRequiredField(
                                                <Input size="small" name="Descrizione" onChange={this.onChangeText} />
                                            )}
                                        </FormItem>
                                        <FormItem  {...formItemLayout} label="Note">
                                            <TextArea autosize={{ minRows: 5 }}
                                                name="Note"
                                                value={this.state.Note}
                                                onChange={this.onChangeText} />
                                        </FormItem>
                                        <FormItem  {...formItemLayout} label="Scadenza">
                                            {this.getRequiredFieldInit(moment(this.state.Scadenza),
                                                <DatePicker allowClear={false}
                                                    format='L'
                                                    onChange={(data, dateString) => this.onChangeData(data, dateString, 'Scadenza')}
                                                    locale={locale}
                                                    name="Scadenza" />)
                                            }
                                        </FormItem>
                                        <FormItem {...formItemLayout} label="Gruppi">
                                            {this.getRequiredFieldFull("Gruppi", selectedGroups,
                                                <TTSelect mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Seleziona Gruppi"
                                                    onChange={this.gruppiChange}>
                                                    {this.renderSelect(this.state.GruppiSource, "Id", "Titolo")}
                                                </TTSelect>)
                                            }
                                        </FormItem>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <AdempimentoEditAttivita attivita={this.state.Attivita} updateAttivita={this.onAttivitaUpdate} />
                            </Col>
                        </Row>
                        <br />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <ListaCustomers groups={selectedGroups}/>
                    </Col>
                </Row>
            </div>
        )
    }
}
const AdempimentoEdit = Form.create()(withRouter(TTComponent(PageAdempimentoEdit, 'Adempimento', ComponentService)));
export default AdempimentoEdit;

// Componente per la copia
const ComponentServiceCopy: symbol = SERVICE_IDENTIFIER.ADEMPIMENTO_COPY_SERVICE;
export const AdempimentoCopy = Form.create()(withRouter(TTComponent(PageAdempimentoEdit, 'Copia Adempimento', ComponentServiceCopy)));
