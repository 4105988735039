export const PROVIDERS_REGISTRATION = {
    TEST: 'Test',
    RICERCASTIPULATI: 'RicercaStipulati',
    RICERCAUTENTI: 'RicercaUtenti',
    EDITUTENTI: 'EditUtenti',
    ADEMPIMENTI: 'Adempimenti',
    ADEMPIMENTO: 'Adempimento',
    ADEMPIMENTOCUSTOMER: 'AdempimentoCustomer',
    ADEMPIMENTOEDIT: 'AdempimentoEdit',
    ADEMPIMENTONOTIFICATION: 'AdempimentoNotification',
    GRUPPO: 'Gruppo',
    GRUPPOEDIT: 'GruppoEdit',
    CLIENTE: 'Cliente',
    CLIENTEUTENTE: 'ClienteUtente',
    CLIENTEUTENTEEDIT: 'ClienteUtenteEdit',
    CLIENTI: 'Clienti',
    DRAWER: 'Drawer',
    HOME: 'Home',
    SERVIZI: 'Servizi',
    CIRCOLARI: 'Circolari',
    CIRCOLAREEDIT: 'CircolareEdit',
    TENANTEDIT: 'TenantEdit',
    FSVIRTUALE: 'FSVirtuale',
    IMPORTFATTURATO: 'ImportFatturato',
    IMPORTCLIENTI: 'ImportClienti',
    IMPORTSCADENZE: 'ImportScadenze',
    FIRMA: 'Firma',
    APISERVICEEXT: 'ApiServiceExt',
    APISERVICE: 'ApiService',
    UTENTIBOSERVICE: 'PageUtentiBORicerca',
    EDITUTENTIBO: 'UtentiBOEdit',
    LICENZE: 'Licenze',
    LICENZACLIENTI: 'LicenzaClienti'
};

