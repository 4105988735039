import { ModelBase } from "./ModelBase"
import { ClientiProjection } from "./ClientiState"

export class LicenzeProjection extends ModelBase {
    public LicenzaId: string
    public NumeroClienti: number
    public Scadenza: Date
    public ClientiRimanenti: number
}

export class LicenzaClientiModel extends LicenzeProjection {
    public ClientiAssegnati: ClientiProjection[] 
}

export class AssegnaLicenzaClientiInput {
    public IdLicenza: string
    public IdClienti: Array<string>
}

export class BuyOutputModel extends ModelBase {
    public UrlPagamento: string
}

export class StatoLicenzaModel extends ModelBase
{
    public Descrizione: string
    public Stato: number
    public ProssimoRinnovo: Date
    public LicenzaId: string
}
