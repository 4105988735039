import { IAppLayoutModel } from "../../Models/AppLayoutModel";
import { injectable, inject } from "inversify";
import SERVICE_IDENTIFIER from "../../Wires/Identifiers";
import { IApiService } from "./ApiService";
import * as apis from '../../Constants/ApiUrlConstants'
import { IMenuModel } from "../ToolbarService/IMenuItems";
import IoCContainer from '../../Wires/Bootstrapper';

export interface IAppLayoutService {
    getMenuForRoute(): Promise<IMenuModel | any>;
}
@injectable()
export class AppLayoutService implements IAppLayoutService {
    apiClient: IApiService;
    public constructor() {
        this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }
    ///AA recupera la lista di rotte di cui l'utente dispone l'autorizzazione
    async getMenuForRoute() {    
        return this.apiClient.postAsync<IMenuModel>(apis.GetRoutes, null);
        } 
    }
